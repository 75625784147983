<template>
  <div class="aspect-w-3 aspect-h-2 relative block w-full bg-gray overflow-hidden lg:max-h-screen">
    <img
      :src="`${coverPhotoUrl}?w=2560&ar=1.5&fit=crop`"
      :alt="title"
      :class="[
        'absolute h-full w-full object-cover z-10 transform-gpu transition-all ease-in-out duration-300',
        !isImageLoaded ? 'opacity-0' : 'opacity-100'
      ]"
      @load="imageLoaded"
    />
    <div class="flex items-center justify-center bg-gray">
      <AtomLogo :width="98" :height="58" />
    </div>
    <slot>
      <button
        v-if="isAdmin"
        class="
          lg:hover:bg-orange-500
          absolute
          z-20
          bottom-auto
          left-auto
          right-4
          top-4
          flex
          items-center
          justify-center
          w-10
          h-10
          text-white
          border-0
          rounded-full
          transition
          duration-300
          ease-in-out
          lg:bg-black
        "
        :title="actionTitle"
        @click.prevent="buttonAction"
      >
        <BaseIcon class="w-10">
          <EditIcon />
        </BaseIcon>
      </button>
    </slot>
  </div>
</template>

<script>
  import { Auth } from 'aws-amplify'
  import { mapGetters } from 'vuex'

  export default {
    props: {
      title: String,
      actionTitle: String,
      coverPhotoUrl: String,
      isOwner: {
        type: Boolean,
        default: false
      }
    },
    emits: ['show-panel'],
    data() {
      return {
        isImageLoaded: null
      }
    },
    computed: {
      ...mapGetters({
        loggedInUser: 'auth/getLoggedInUser'
      }),
      isAdmin() {
        return this.loggedInUser && this.loggedInUser.admin
      }
    },
    methods: {
      buttonAction() {
        this.$emit('show-panel')
      },
      imageLoaded() {
        this.isImageLoaded = true
      }
    }
  }
</script>
