<template>
  <section
    class="flex flex-wrap gap-4 sm:overflow-hidden"
    :class="[
      isSimple ? '' : 'border border-gray sm:border sm:rounded-sm',
      isSimple && index > 3 && index <= medium ? 'hidden md:block' : null,
      isSimple && index > medium ? 'hidden 2xl:block' : null
    ]"
  >
    <RouterLink
      v-if="!isStatic"
      class="aspect-w-3 aspect-h-2 group relative block w-full bg-gray overflow-hidden"
      :to="profileUrl"
    >
      <img
        v-if="photoUrl"
        :class="[
          'absolute h-full w-full object-cover z-10 transform-gpu transition-all ease-in-out duration-300',
          !isImageLoaded ? 'opacity-0' : 'opacity-100',
          isSimple ? 'scale-105 lg:group-hover:scale-110' : 'lg:group-hover:scale-105'
        ]"
        :src="photoUrl"
        :alt="`@${user.username}`"
        @load="imageLoaded"
      />
      <div
        class="
          lg:group-hover:bg-orange-500 lg:group-hover:text-white
          flex
          items-center
          justify-center
          bg-gray
          transition-colors
          duration-300
          ease-in-out
        "
      >
        <AtomLogo />
      </div>
    </RouterLink>

    <div v-else class="aspect-w-3 aspect-h-2 group relative block w-full bg-gray overflow-hidden">
      <img
        v-if="photoUrl"
        :class="['absolute h-full w-full object-cover z-10', !isImageLoaded ? 'opacity-0' : 'opacity-100']"
        :src="photoUrl"
        :alt="`@${user.username}`"
        @load="imageLoaded"
      />
      <div
        class="
          lg:group-hover:bg-orange-500 lg:group-hover:text-white
          flex
          items-center
          justify-center
          bg-gray
          transition-colors
          duration-300
          ease-in-out
        "
      >
        <AtomLogo />
      </div>
    </div>
    <template v-if="!isSimple">
      <h3 class="flex justify-center px-4 w-full text-2xl font-bold">{{ name }}</h3>
      <div class="flex flex-col flex-wrap justify-between pb-4 px-4 w-full">
        <div>
          <dl class="grid gap-4 grid-cols-3">
            <div>
              <dt>Events</dt>
              <dd class="text-lg font-bold">{{ numEvents }}</dd>
            </div>
            <!-- <div>
              <dt>Joined</dt>
              <dd class="text-lg font-bold">
                <time :datetime="user.created_at">
                  <AtomFormattedDate :date="user.created_at" format="short"></AtomFormattedDate>
                </time>
              </dd>
            </div> -->
            <div>
              <dt>Tags</dt>
              <dd class="text-lg font-bold">{{ numTagged }}</dd>
            </div>
            <div>
              <dt>Likes</dt>
              <dd class="text-lg font-bold">{{ numLikes }}</dd>
            </div>
          </dl>
        </div>
        <ul class="w-full sm:flex sm:space-x-4">
          <li v-if="!isCurrentUser && loggedInUser" class="flex-1">
            <AtomCTA
              type="button"
              url="/"
              :title="isFollowing ? 'Unfollow' : isFollower ? 'Follow back' : 'Follow'"
              :disabled="isUpdating"
              :is-doing="isUpdating"
              :negative-action="isFollowing"
              @click.prevent="updateFollowStatus"
            />
          </li>
          <li v-else-if="!loggedInUser" class="flex-1">
            <AtomCTA type="RouterLink" url="/join" title="Join to follow" />
          </li>
          <li class="flex-1">
            <AtomCTA :url="profileUrl" :title="viewAction" ghost />
          </li>
        </ul>
      </div>
    </template>
  </section>
</template>

<script>
  import { Auth } from 'aws-amplify'
  import { mapGetters } from 'vuex'

  export default {
    props: {
      user: Object,
      index: Number,
      medium: Number,
      isStatic: {
        type: Boolean,
        default: false
      },
      isSimple: {
        type: Boolean,
        default: false
      }
    },
    emits: ['updated'],
    data() {
      return {
        isUpdating: false,
        isImageLoaded: false
      }
    },
    computed: {
      ...mapGetters({
        userJWT: 'getJWT',
        loggedInUser: 'auth/getLoggedInUser'
      }),
      viewAction() {
        return this.loggedInUser && this.user.uid === this.loggedInUser.id ? "It's you!" : 'View profile'
      },
      isCurrentUser() {
        if (this.loggedInUser) return this.user.uid === this.loggedInUser.id

        return false
      },
      isFollowing() {
        return this.user.is_following
      },
      isFollower() {
        return this.user.is_follower
      },
      numEvents() {
        return String(this.user.num_events).padStart(3, '0')
      },
      numTagged() {
        return this.user.num_tagged ? String(this.user.num_tagged).padStart(3, '0') : '000'
      },
      numLikes() {
        return this.user.num_likes ? String(this.user.num_likes).padStart(3, '0') : '000'
      },
      joinDate() {
        return this.user.created_at
      },
      profileUrl() {
        return `/${this.user.username}`
      },
      name() {
        return this.user.first_name !== null && this.user.last_name !== null
          ? `${this.user.first_name} ${this.user.last_name}`
          : null
      },
      photoUrl() {
        return this.user && this.user.filename
          ? `${process.env.VUE_APP_IMGIX}/users/${this.user.uid}/${this.user.filename}?w=768&ar=1.5&fit=crop`
          : null
      }
    },
    methods: {
      async updateFollowStatus() {
        this.isUpdating = true

        const res = await this.apiRequest(
          `/user/${this.user.uid}/follow`,
          null,
          null,
          {
            Authorization: this.userJWT
          },
          'POST'
        )

        this.isUpdating = false

        this.$emit('updated', this.user.uid, res)
      },
      imageLoaded() {
        this.isImageLoaded = true
      }
    }
  }
</script>
