<template>
  <Form ref="form" :validation-schema="schema" :class="classes" :initial-values="initialValues" @submit="submitForm">
    <div v-for="field in fields" :key="field.id" :class="field.classes">
      <label
        v-if="field.label"
        :for="field.id"
        :class="['block text-black text-sm font-medium cursor-pointer', field.type === 'checkbox' ? 'ml-2' : null]"
      >
        {{ field.label }}<template v-if="field.isRequired"> *</template>
      </label>
      <div v-if="field.type">
        <Field
          :id="field.id"
          :ref="field.id"
          v-model="input[field.id]"
          :name="field.id"
          :as="field.elem"
          :type="field.type"
          :rules="field.isRequired"
          :disabled="field.disabled"
          :hidden="field.hidden"
          :placeholder="field.placeholder"
          :max="field.max"
          :min="field.min"
          :class="[
            'form-input block focus:border-orange-500 rounded-sm outline-none ring-0 focus:outline-none shadow-none duration-150 ease-in-out focus:ring-0',
            field.type === 'checkbox'
              ? 'w-6 h-6 text-orange-500 checked:bg-orange-500'
              : field.type === 'date'
              ? 'min-h-50 w-full pl-4 pr-2 py-3'
              : field.small
              ? 'min-h-40 w-full p-2'
              : 'min-h-40 w-full px-4 py-3',
            field.autoExpand ? 'transition-[height]' : 'transition',
            field.elem === 'textarea'
              ? field.autoExpand
                ? 'focus:h-12 h-12 overflow-y-hidden resize-none'
                : 'resize-none h-28'
              : null,
            field.inputClasses,
            field.disabled ? 'bg-gray border-gray' : 'border-black'
          ]"
          :value="field.type === 'checkbox' ? true : null"
          @keydown.enter="handleEnter"
        />

        <div v-if="field.instruction" class="flex flex-wrap mt-1 text-sm">
          <p class="mr-1" v-html="field.instruction.content"></p>
          <RouterLink
            v-if="field.instruction.url"
            :to="field.instruction.url"
            class="lg:hover:border-transparent inline-block border-b border-black transition duration-150 ease-in-out"
            @click.prevent="panelCta(field.instruction.url)"
          >
            {{ field.instruction.cta }}
          </RouterLink>
        </div>
        <ErrorMessage :name="field.id" class="text-orange-500 text-sm" />
      </div>
      <div v-else>
        <Field
          :id="field.id"
          :ref="field.id"
          v-model="select[field.id]"
          :name="field.id"
          :as="field.elem"
          :rules="field.isRequired"
          :multiple="field.multiple"
          :size="field.size"
          :disabled="field.disabled"
          :class="[
            'form-input block px-4 py-3 w-full border-black focus:border-orange-500 rounded-sm focus:outline-none shadow-none transition duration-150 ease-in-out focus:ring-0',
            field.disabled ? 'bg-gray border-gray' : 'border-black'
          ]"
          @change="selected"
        >
          <option v-if="!field.multiple" disabled selected value="">Please select…</option>

          <template v-if="field.children && field.children.length">
            <component
              :is="tag"
              v-for="{ tag, text, value, disabled } in field.children"
              :key="value"
              :value="value"
              :disabled="disabled"
              :selected="field.multiple && field.selected && field.selected.includes(value)"
            >
              {{ text }}
            </component>
          </template>
        </Field>
        <div v-if="field.instruction" class="flex flex-wrap mt-1 text-sm">
          <p class="mr-1" v-html="field.instruction.content"></p>
          <RouterLink
            v-if="field.instruction.url"
            :to="field.instruction.url"
            class="lg:hover:border-transparent inline-block border-b border-black transition duration-150 ease-in-out"
            @click.prevent="panelCta(field.instruction.url)"
          >
            {{ field.instruction.cta }}
          </RouterLink>
        </div>
        <ErrorMessage :name="field.id" class="text-orange-500 text-sm" />
      </div>
    </div>

    <div v-if="!hideSubmit && !inlineSubmit" class="sm:col-span-2">
      <span class="inline-flex w-full rounded-sm">
        <button
          ref="submitBtn"
          :disabled="isLoading"
          type="submit"
          :class="[
            'w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-sm text-white bg-orange-500 focus:outline-none focus:border-orange-500 transition ease-in-out duration-150',
            !isLoading ? 'hover:bg-orange-300' : 'bg-orange-500 cursor-default'
          ]"
        >
          <span v-if="!isLoading">{{ cta }}</span>
          <AtomLoader v-else></AtomLoader>
        </button>
      </span>
    </div>

    <div v-if="inlineSubmit && showSubmit" class="absolute bottom-1 right-0">
      <span class="inline-flex w-full">
        <button
          ref="submitBtn"
          :disabled="isLoading"
          type="submit"
          :class="[
            'w-10 h-10 rounded-full text-black bg-transparent flex items-center justify-center lg:hover:bg-transparent shadow-none focus:outline-none focus:ring-0 focus:bg-gray-100 transition-colors duration-150 ease-in-out',
            !isLoading ? 'lg:hover:text-orange-700 group-hover:text-orange-500' : 'cursor-default'
          ]"
        >
          <BaseIcon class="w-7 h-7"><MessageIcon /></BaseIcon>
        </button>
      </span>
    </div>
  </Form>
</template>

<script>
  import { useFormValues, Field, Form, ErrorMessage } from 'vee-validate'
  import * as yup from 'yup'
  import { mapGetters } from 'vuex'

  export default {
    components: {
      ErrorMessage,
      Field,
      Form
    },
    inject: ['panel'],
    props: {
      classes: String,
      fields: Array,
      disabled: {
        type: Boolean,
        default: false
      },
      initialValues: Object,
      cta: String,
      action: String,
      isSaving: {
        type: Boolean,
        default: false
      },
      refresh: {
        type: Boolean,
        default: false
      },
      reset: {
        type: Boolean,
        default: false
      },
      hideSubmit: {
        type: Boolean,
        default: false
      },
      inlineSubmit: {
        type: Boolean,
        default: false
      },
      showSubmit: {
        type: Boolean,
        default: true
      }
    },
    emits: ['form-submitted'],
    data() {
      const schema = yup.object().shape({
        first_name: yup.string().required('First name is required').label('First name').nullable(),
        last_name: yup.string().required('Last name is required').label('Last name').nullable(),
        email: yup.string().required('Email address is required').email().label('Email address').nullable(),
        message: yup.string().required('Message is required').label('Message').nullable(),
        name: yup.string().required('Name is required').label('Name').nullable(),
        address_1: yup.string().required('Address line 1 is required').label('Address Line 1').nullable(),
        address_2: yup.string().nullable(),
        address_3: yup.string().nullable(),
        town: yup.string().required('Town/City is required').label('Town'),
        town_city: yup.string().nullable(),
        post_code: yup.string().required('Postcode is required').label('Postcode').nullable(),
        country: yup.string().required('Country is required').label('Country'),
        telephone: yup.string().required('Telephone is required').label('Telephone').nullable(),
        flickrUrl: yup.string().label('Flickr Album URL').nullable(),
        eventAlbum: yup.string().label('This is an official event album').nullable(),
        albumName: yup.string(),
        circuit_uid: yup.string().required('Circuit is required').label('Circuit').nullable(),
        series_uid: yup.string().required('Series is required').label('Series').nullable(),
        layout_uid: yup.string().label('Layout').required().nullable(),
        organiser_uid: yup.string().required('Organiser is required').label('Organiser').nullable(),
        drive: yup.string().required('Organiser is required').label('Drive').nullable(),
        format_uid: yup.string().required('Format is required').label('Format').nullable(),
        start_date: yup.string().required('Start date is required').label('Start Date').nullable(),
        laptime: yup
          .string()
          .required('Lap time is required')
          .matches(/^([0-5]?[0-9])(:[0-5]{1}[0-9]{1}(\.[0-9]{1,3})?)?$/, 'Incorrect lap time format')
          .label('Lap Time')
          .nullable(),
        youtube_id: yup.string().required('YouTube video is required').label('YouTube Video URL').nullable(),
        end_date: yup.string().nullable(),
        description: yup.string().nullable(),
        url: yup.string().nullable(),
        private: yup.string().label('Do not display this lap time').nullable(),
        model: yup.string().required('Model is required').nullable(),
        manufacturer: yup.string().required('Manufacturer is required').nullable(),
        date: yup.string().required('Date is required').nullable(),
        users_uid: yup.array().required('Friends are required').nullable(),
        car_uid: yup.array().nullable(),
        comment: yup.string().required(null).nullable()
      })

      return {
        schema,
        select: {},
        input: {},
        hasDate: false,
        hasCircuit: false,
        hasLayouts: false,
        hasUsers: false,
        hasSlug: false,
        event: null
      }
    },
    computed: {
      ...mapGetters({
        userJWT: 'getJWT'
      }),
      isLoading() {
        return this.isSaving
      }
    },
    watch: {
      refresh: {
        handler() {
          this.$refs.submitBtn.click()
        }
      }
    },
    mounted() {
      if (this.$refs.reply && this.$refs.reply.$el) this.setupReply()
    },
    created() {
      this.fields.forEach((field) => {
        if (field.elem === 'select') {
          if (field.multiple) {
            this.select[field.id] = field.selected ? field.selected : ''
          } else {
            this.select[field.id] = field.selected ? field.selected : ''
            this.select[field.id] = this.select[field.id].trim()
          }
          if (field.id === 'users_uid') this.hasUsers = true
          if (field.id === 'circuit_uid') this.hasCircuit = true
          if (field.id === 'layout_uid') this.hasLayouts = true
        } else {
          if (field.id === 'date') this.hasDate = true
          if (field.id === 'slug') this.hasSlug = true
          this.input[field.id] = this.initialValues ? this.initialValues[field.id] : null
        }
      })
    },
    methods: {
      async submitForm(values, { resetForm }) {
        const usersUid = this.$refs.users_uid
        const laptime = this.$refs.laptime
        let data = values

        for (const [key, value] of Object.entries(data)) {
          if (value === 'none') data[key] = null
        }

        if (usersUid) {
          const selectedUsers = [...usersUid.$el.options].filter((x) => x.selected).map((x) => x.value)

          data.users_uid = [...new Set(selectedUsers)]
        }

        if (laptime) {
          const isPrivate = this.$refs.private.$el.checked
          const value = laptime.$el.value
          const minutes = value.split(':')
          const seconds = minutes[1] ? minutes[1].split('.') : 0
          const convertedMinutes = Math.ceil(minutes[0] * 60 * 1000)
          const convertedSeconds = seconds && seconds[0] ? Math.ceil(seconds[0] * 1000) : 0
          const convertedMilliseconds = seconds && seconds[1] ? parseInt(seconds[1].padEnd(3, '0')) : 0

          data.laptime = convertedMinutes + convertedSeconds + convertedMilliseconds
          data.private = isPrivate
        }

        if (this.hasSlug) {
          const circuitField = this.$refs['circuit_uid']
          const dateField = this.$refs['start_date']
          const dateVal = dateField.$el.value
          const year = dateVal.substring(0, 4)
          const month = dateVal.substring(5, 7)
          const day = dateVal.substring(8, 10)
          const date = `${year}-${month}-${day}`
          const circuitVal = circuitField.$el.options[circuitField.$el.options.selectedIndex].text
            .toString()
            .toLowerCase()
            .trim()
            .replace(/\s+/g, '-')
            .replace(/&/g, '-y-')
            .replace(/[^\w\-]+/g, '')
            .replace(/\-\-+/g, '-')

          const slug = `${circuitVal}-${date}`

          data.slug = slug
        }

        this.$emit('form-submitted', data)

        if (this.reset) resetForm()
      },
      async selected(e) {
        const field = e.target
        const isEventField = field.id === 'event_uid'
        const isCircuitField = field.id === 'circuit_uid'

        if (isEventField) {
          if (this.hasDate) {
            const dateRegex = /^[0-9]{2}[\/][0-9]{2}[\/][0-9]{4}$/g
            const selectedText = field.options[field.options.selectedIndex].text
            const splitSelectedText = selectedText.split('-')
            const date = splitSelectedText[0].trim()

            if (dateRegex.test(date)) {
              const splitDate = date.split('/')

              this.input.date = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`
            }
          }

          if (this.hasCircuit) {
            const selectedVal = field.options[field.options.selectedIndex].value

            if (selectedVal !== 'none') {
              try {
                this.event = await this.apiRequest(`/event/${selectedVal}`, null, null, {
                  Authorization: this.userJWT
                })

                this.select['circuit_uid'] = this.event.circuit_uid

                if (this.hasLayouts) {
                  const layoutFormField = this.fields.find((field) => field.id === 'layout_uid')

                  try {
                    const circuitLayouts = await this.apiRequest(
                      `/circuit/${this.select['circuit_uid']}/layouts`,
                      null,
                      null,
                      {
                        Authorization: this.userJWT
                      }
                    )

                    layoutFormField.children = []
                    layoutFormField.disabled = null

                    for (const layout of circuitLayouts) {
                      layoutFormField.children.push({
                        tag: 'option',
                        text: layout.name,
                        value: layout.layout_uid
                      })
                    }
                  } catch (e) {
                    if (e) console.log(`Error getting circuit layouts`)
                  }
                }
                this.select['layout_uid'] = this.event.layout_uid
              } catch (e) {
                if (e) console.log(`Error getting circuits`)
              }
            }
          }
        }

        if (isCircuitField) {
          const selectedVal = field.options[field.options.selectedIndex].value
          const layoutFormField = this.fields.find((field) => field.id === 'layout_uid')

          const layouts = await this.apiRequest(`/circuit/${selectedVal}/layouts`, null, null, {
            Authorization: this.userJWT
          })

          layoutFormField.children = []

          layouts.forEach((layout) => {
            layoutFormField.children.push({
              tag: 'option',
              text: layout.name,
              value: layout.layout_uid
            })
          })

          layoutFormField.disabled = null
          this.select['layout_uid'] = ''
        }
      },
      async panelCta(url) {
        this.panel.$refs.panelBackground.click()

        this.$router.push({
          path: url,
          query: { p: '1' }
        })
      },
      async setupReply() {
        const el = this.$refs.reply.$el

        el.focus()
        // el.addEventListener('keydown', this.createReply, true)
      },
      async createReply(e) {
        const el = this.$refs.reply.$el
        const reply_to_uid = this.$refs.reply_to_uid.$el

        if (e.keyCode == 13 && !e.shiftKey) {
          e.preventDefault()

          this.$emit('form-submitted', {
            reply: el.value,
            reply_to_uid: reply_to_uid.value
          })

          el.value = null
        }
      },
      handleEnter(e) {
        if (!e.shiftKey) {
          e.preventDefault()
          this.$refs.submitBtn.click()
        }
      }
    }
  }
</script>

<style>
  [role='alert'] {
    @apply mt-1 text-orange-500 text-sm font-medium;
  }

  input[type='time']::-webkit-calendar-picker-indicator {
    background: none;
  }
</style>
