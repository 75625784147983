<template>
  <div class="border-b border-gray">
    <div :class="[isFullWidth ? 'mx-auto max-w-screen-2xl' : 'pb-4 md:pb-0 md:px-4 lg:px-8']">
      <div :class="[isFullWidth ? 'md:px-4 lg:px-8' : null, 'sm:flex sm:items-center sm:justify-between']">
        <AtomSectionHeader :title="title" classes="block" />
        <div class="block px-4 sm:pt-4 md:px-0 lg:pt-8">
          <slot></slot>
        </div>
      </div>
      <div :class="['mt-4', isFullWidth ? 'pb-4 md:pb-0 md:px-4 lg:px-8' : null]">
        <div class="px-4 md:hidden">
          <label for="selected-tab" class="sr-only">Select a tab</label>
          <select
            id="selected-tab"
            v-model="currentTab"
            name="selected-tab"
            class="
              block
              pl-3
              pr-10
              py-2
              w-full
              text-base
              border-gray
              focus:border-orange-500
              rounded-sm
              focus:outline-none
              focus:ring-orange-500
            "
            @change="gotoTab"
          >
            <option v-for="link in links" :key="link.url" :value="link.url">
              {{ link.title }}
            </option>
          </select>
        </div>
        <div :class="['hidden mx-auto md:block', !isFullWidth ? 'max-w-screen-2xl' : null]">
          <nav class="flex -mb-px overflow-hidden overflow-x-scroll space-x-8 lg:overflow-hidden">
            <RouterLink
              v-for="link in links"
              :key="link.url"
              :to="link.url"
              class="
                text-gray
                hover:text-orange-500
                whitespace-nowrap
                text-lg
                font-bold
                border-b-2 border-transparent
                transition-colors
                duration-300
                ease-in-out
              "
              active-class="text-orange-500 border-orange-500"
            >
              <span class="flex pb-2" @click="updateSelect(link.url)">
                {{ link.title }}
              </span>
            </RouterLink>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: String,
      links: Array,
      isFullWidth: Boolean
    },
    data() {
      return {
        currentTab: this.$route.path
      }
    },
    methods: {
      gotoTab(e) {
        this.$router.push({
          path: this.currentTab
        })
      },
      updateSelect(path) {
        this.currentTab = path
      }
    }
  }
</script>
