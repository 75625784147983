<template>
  <article class="border border-gray space-y-4">
    <div class="flex items-center p-4 pb-0 space-x-4">
      <AtomUserIcon :user-uid="item.user_uid" :thumbnail="item.user_thumbnail" :icon="userIcon" class="flex-shrink-0" />

      <div class="flex-1 flex-wrap w-full">
        <!-- user -->
        <RouterLink
          v-if="item.full_name && item.full_name !== 'Tracklimit'"
          class="
            lg:hover:border-orange-500
            text-orange-500
            border-b border-transparent
            transition
            duration-150
            ease-in-out
          "
          :to="profileUrl"
        >
          {{ item.full_name }}
        </RouterLink>
        <template v-else-if="item.full_name == 'Tracklimit'">{{ item.full_name }}</template>
        <template v-else>Someone</template>
        <!-- liked -->
        <template v-if="item.like_uid">
          liked a
          <button
            v-if="item.like_photograph_uid"
            class="
              lg:hover:border-orange-500
              text-orange-500
              border-b border-transparent
              transition
              duration-150
              ease-in-out
            "
            @click.prevent="showPhotograph(item.like_photograph_uid)"
          >
            photograph
          </button>
          <button
            v-else-if="item.like_video_uid"
            class="
              lg:hover:border-orange-500
              text-orange-500
              border-b border-transparent
              transition
              duration-150
              ease-in-out
            "
            @click.prevent="showVideo(item.like_video_uid)"
          >
            video
          </button>
          <template v-if="item.like_photograph_album_uid">
            from
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="albumUrl"
            >
              {{ item.like_photograph_album_name }}</RouterLink
            >
          </template>
          <template v-if="item.like_video_event_slug">
            from
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="eventUrl"
            >
              {{ item.like_video_circuit_name }} (<AtomFormattedDate
                :date="item.like_video_event_date"
                format="short"
              />)</RouterLink
            >
          </template>
        </template>
        <!-- commented -->
        <template v-else-if="item.comment">
          <template v-if="item.comment_reply_uid"> replied to a comment on </template>
          <template v-else> commented on </template>
          <!-- a photograph -->
          <template v-if="item.comment_photograph_uid">
            a
            <button
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              @click.prevent="showPhotograph(item.comment_photograph_uid)"
            >
              photograph
            </button>
            <template v-if="item.comment_photograph_album_uid">
              in
              <RouterLink
                class="
                  lg:hover:border-orange-500
                  text-orange-500
                  border-b border-transparent
                  transition
                  duration-150
                  ease-in-out
                "
                :to="albumUrl"
              >
                {{ item.comment_photograph_album_name }}
              </RouterLink>
            </template>
          </template>
          <!-- an album (from an event) -->
          <template v-if="item.comment_album_uid">
            an
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="albumUrl"
            >
              album</RouterLink
            >
            <template v-if="eventUrl">
              from
              <RouterLink
                class="
                  lg:hover:border-orange-500
                  text-orange-500
                  border-b border-transparent
                  transition
                  duration-150
                  ease-in-out
                "
                :to="eventUrl"
              >
                {{ item.comment_album_event_circuit_name }} (<AtomFormattedDate
                  :date="item.comment_album_event_date"
                  format="short"
                />)
              </RouterLink>
            </template>
          </template>
          <!-- a video (from an event) -->
          <template v-if="item.comment_video_uid">
            a
            <button
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              @click.prevent="showVideo(item.comment_video_uid)"
            >
              video
            </button>
            <template v-if="eventUrl">
              from
              <RouterLink
                class="
                  lg:hover:border-orange-500
                  text-orange-500
                  border-b border-transparent
                  transition
                  duration-150
                  ease-in-out
                "
                :to="eventUrl"
              >
                {{ item.comment_video_circuit_name }} (<AtomFormattedDate
                  :date="item.comment_video_event_date"
                  format="short"
                />)
              </RouterLink>
            </template>
          </template>
          <!-- an event -->
          <template v-else-if="eventUrl && !albumUrl">
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="eventUrl"
            >
              {{ item.comment_event_circuit_name }} (<AtomFormattedDate
                :date="item.comment_event_date"
                format="short"
              />)
            </RouterLink>
          </template>
          <template v-else-if="item.comment_circuit_name">
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="circuitUrl"
            >
              {{ item.comment_circuit_name }}
            </RouterLink>
          </template>
          <template v-else-if="item.comment_organiser_name">
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="organiserUrl"
            >
              {{ item.comment_organiser_name }}
            </RouterLink>
          </template>
        </template>
        <!-- added -->
        <!-- photograph (to an album) -->
        <template v-else-if="item.photograph_uid">
          added a
          <button
            class="
              lg:hover:border-orange-500
              text-orange-500
              border-b border-transparent
              transition
              duration-150
              ease-in-out
            "
            @click.prevent="showPhotograph(item.photograph_uid)"
          >
            photograph
          </button>
          <template v-if="albumUrl">
            to
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="albumUrl"
              >{{ item.photograph_album_name }} (<AtomFormattedDate
                :date="item.photograph_event_date"
                format="short"
              />)
            </RouterLink>
          </template>
        </template>
        <!-- album (for an event) -->
        <template v-else-if="albumUrl">
          added an
          <RouterLink
            class="
              lg:hover:border-orange-500
              text-orange-500
              border-b border-transparent
              transition
              duration-150
              ease-in-out
            "
            :to="albumUrl"
            >album</RouterLink
          >
          <template v-if="eventUrl">
            to
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="eventUrl"
              >{{ item.album_circuit_name }} (<AtomFormattedDate :date="item.album_event_date" format="short" />)
            </RouterLink>
          </template>
        </template>
        <!-- video (to an event) -->
        <template v-else-if="item.video_uid && !item.laptime_video_uid">
          added a
          <button
            class="
              lg:hover:border-orange-500
              text-orange-500
              border-b border-transparent
              transition
              duration-150
              ease-in-out
            "
            @click.prevent="showVideo(item.video_uid)"
          >
            video
          </button>
          <template v-if="item.video_event_slug">
            to
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="eventUrl"
              >{{ item.video_circuit_name }} (<AtomFormattedDate :date="item.video_event_date" format="short" />)
            </RouterLink>
          </template>
        </template>
        <!-- laptime (to an event) -->
        <template v-else-if="item.laptime_video_uid">
          added a
          <button
            class="
              lg:hover:border-orange-500
              text-orange-500
              border-b border-transparent
              transition
              duration-150
              ease-in-out
            "
            @click.prevent="showVideo(item.laptime_video_uid)"
          >
            lap time (<AtomTime :duration="item.laptime_time"></AtomTime>)
          </button>
          <template v-if="eventUrl">
            to
            <RouterLink
              class="
                lg:hover:border-orange-500
                text-orange-500
                border-b border-transparent
                transition
                duration-150
                ease-in-out
              "
              :to="eventUrl"
              >{{ item.laptime_video_circuit_name }} (<AtomFormattedDate
                :date="item.laptime_video_event_date"
                format="short"
              />)
            </RouterLink>
          </template>
        </template>
        <!-- attending an event -->
        <template v-else-if="item.attending_event_circuit_name">
          {{ attendStatus }}
          <RouterLink
            class="
              lg:hover:border-orange-500
              text-orange-500
              border-b border-transparent
              transition
              duration-150
              ease-in-out
            "
            :to="eventUrl"
            >{{ item.attending_event_circuit_name }} (<AtomFormattedDate
              :date="item.attending_event_date"
              format="short"
            />)
          </RouterLink>
        </template>
        <p class="mt-1 w-full text-sm">
          {{ timeAgo }}
        </p>
      </div>

      <button
        v-if="
          item.comment_uid ||
          item.album_uid ||
          item.photograph_uid ||
          item.like_photograph_uid ||
          item.laptime_video_uid ||
          item.video_uid
        "
        :class="[
          'flex-shrink-0 text-white rounded-full disabled:opacity-50 disabled:cursor-default mr-2 lg:mb-0 w-10 h-10 inline-flex items-center justify-center border-0 text-sm font-medium focus:outline-none transition-colors ease-in-out duration-300',
          isLiked ? 'bg-orange-500 lg:hover:bg-orange-700 active:bg-orange-700' : 'bg-gray lg:hover:bg-orange-500'
        ]"
        :disabled="isLiking"
        :title="isLiked ? 'Unlike' : 'Like'"
        @click.prevent="likeContent"
      >
        <BaseIcon class="w-7 h-7"><LoveIcon /></BaseIcon>
      </button>
    </div>

    <div class="transition-opacity duration-150 ease-in-out">
      <template v-if="item.comment">
        <div class="inline-flex px-4" :class="[hasVideo || thumbnailUrl ? 'mb-4' : 'pb-0']" v-html="item.comment"></div>
        <button
          v-if="item.comment_photograph_uid"
          class="group aspect-w-3 aspect-h-2 relative block w-full bg-gray overflow-hidden"
          @click.prevent="showPhotograph(item.comment_photograph_uid)"
        >
          <AtomPhotograph
            :thumbnail="thumbnailUrl"
            class="lg:group-hover:scale-105 transform-gpu transition-all duration-150 ease-in-out"
            :is-simple="true"
          />
        </button>
        <RouterLink
          v-if="item.comment_album_thumbnail_uid"
          class="group aspect-w-3 aspect-h-2 relative block w-full bg-gray overflow-hidden"
          :to="albumUrl"
          @click.prevent="goTo(albumUrl)"
        >
          <AtomPhotograph
            :thumbnail="thumbnailUrl"
            class="lg:group-hover:scale-105 transform-gpu transition-all duration-150 ease-in-out"
            :is-simple="true"
          />
        </RouterLink>
        <button
          v-if="item.comment_video_uid"
          class="group aspect-w-16 aspect-h-9 relative block w-full bg-gray overflow-hidden"
          @click.prevent="showVideo(item.comment_video_uid)"
        >
          <AtomVideo :video="youtubeId" :is-simple="true" />
        </button>
      </template>
      <template v-else>
        <button
          v-if="item.like_photograph_uid"
          class="group aspect-w-3 aspect-h-2 relative block w-full bg-gray overflow-hidden"
          @click.prevent="showPhotograph(item.like_photograph_uid)"
        >
          <AtomPhotograph
            :thumbnail="item.like_photograph"
            class="lg:group-hover:scale-105 transform-gpu transition-all duration-150 ease-in-out"
            :is-simple="true"
          />
        </button>

        <RouterLink
          v-if="item.album_thumbnail_uid"
          class="group aspect-w-3 aspect-h-2 relative block w-full bg-gray overflow-hidden"
          :to="albumUrl"
          @click.prevent="goTo(albumUrl)"
        >
          <AtomPhotograph
            :thumbnail="thumbnailUrl"
            class="lg:group-hover:scale-105 transform-gpu transition-all duration-150 ease-in-out"
            :is-simple="true"
          />
        </RouterLink>

        <button
          v-else-if="item.photograph_uid"
          class="group aspect-w-3 aspect-h-2 relative block w-full bg-gray overflow-hidden"
          @click.prevent="showPhotograph(item.photograph_uid)"
        >
          <AtomPhotograph
            :thumbnail="thumbnailUrl"
            class="lg:group-hover:scale-105 transform-gpu transition-all duration-150 ease-in-out"
            :is-simple="true"
          />
        </button>

        <button
          v-if="item.video_uid || item.laptime_video_uid || item.like_video_uid"
          class="group aspect-w-16 aspect-h-9 relative block w-full bg-gray overflow-hidden"
          @click.prevent="
            showVideo(
              item.video_uid
                ? item.video_uid
                : item.laptime_video_uid
                ? item.laptime_video_uid
                : item.like_video_uid
                ? item.like_video_uid
                : null
            )
          "
        >
          <AtomVideo :video="youtubeId" :is-simple="true" />
        </button>
      </template>
      <OrganismComments
        class="pb-4"
        :feed="form"
        :show-comments="hasMultipleComments"
        :comments="comments"
        :content-uid="contentUid"
        @update-comments="updateCommentList"
      />
    </div>
  </article>
</template>

<script>
  import * as timeago from 'timeago.js'
  import { mapGetters } from 'vuex'

  export default {
    props: {
      item: {
        type: Object
      }
    },

    data() {
      const fieldPlaceholder = this.item.album_uid
        ? 'Comment on album…'
        : this.item.video_uid || this.item.like_video_uid || this.item.comment_video_uid
        ? 'Comment on video…'
        : this.item.laptime_video_uid
        ? 'Comment on lap time…'
        : this.item.photograph_uid || this.item.like_photograph_uid
        ? 'Comment on photograph…'
        : 'Write a comment…'

      return {
        comments: null,
        isLoading: false,
        isLiking: false,
        isCommentsVisible: false,
        isLiked:
          this.item.like_video_liked ||
          this.item.like_photograph_liked ||
          this.item.comment_liked ||
          this.item.laptime_video_liked ||
          this.item.video_liked ||
          this.item.album_liked,
        isSaving: null,
        form: {
          cta: this.item.comment ? 'Reply' : 'Comment',
          classes: 'grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8 flex-1 relative group',
          initial: {
            comment: null,
            reply_to_uid: this.item.comment_uid
          },
          fields: [
            {
              id: 'comment',
              elem: 'textarea',
              type: 'text',
              placeholder: fieldPlaceholder,
              isRequired: 'true',
              classes: 'sm:col-span-2',
              autoExpand: true,
              inputClasses: 'border-gray-100 bg-gray-100'
            },
            {
              id: 'reply_to_uid',
              classes: 'hidden',
              elem: 'input',
              type: 'hidden',
              isRequired: 'true',
              hidden: true,
              disabled: true
            }
          ]
        }
      }
    },
    computed: {
      ...mapGetters({
        userJWT: 'getJWT'
      }),
      attendStatus() {
        return Date.parse(this.item.attending_event_date) < Date.now() ? ' went to ' : ' is going to '
      },
      albumUrl() {
        return this.item.photograph_album_uid
          ? `/album/${this.item.photograph_album_uid}`
          : this.item.comment_album_uid
          ? `/album/${this.item.comment_album_uid}`
          : this.item.album_uid
          ? `/album/${this.item.album_uid}`
          : this.item.comment_photograph_album_uid
          ? `/album/${this.item.comment_photograph_album_uid}`
          : this.item.like_photograph_album_uid
          ? `/album/${this.item.like_photograph_album_uid}`
          : null
      },
      circuitUrl() {
        return this.item.comment_circuit_slug ? `/circuit/${this.item.comment_circuit_slug}` : null
      },
      contentUid() {
        return this.item.album_uid
          ? this.item.album_uid
          : this.item.video_uid
          ? this.item.video_uid
          : this.item.laptime_video_uid
          ? this.item.laptime_video_uid
          : this.item.comment_event_uid
          ? this.item.comment_event_uid
          : this.item.comment_photograph_uid
          ? this.item.comment_photograph_uid
          : this.item.comment_album_uid
          ? this.item.comment_album_uid
          : this.item.comment_video_uid
          ? this.item.comment_video_uid
          : this.item.like_photograph_uid
          ? this.item.like_photograph_uid
          : this.item.like_video_uid
          ? this.item.like_video_uid
          : this.item.comment_uid
          ? this.item.comment_uid
          : this.item.attending_event_uid
          ? this.item.attending_event_uid
          : null
      },
      hasComments() {
        return this.comments && !!this.comments.length
      },
      hasMultipleComments() {
        if (!this.hasComments) return false

        // const comments = this.comments.filter((comment) => {
        //   return !!!comment.reply_to_uid
        // })

        return this.comments.length > 1
      },
      timeAgo() {
        return this.item.created_at ? timeago.format(this.item.created_at) : null
      },
      eventUrl() {
        return this.item.comment_album_event_slug
          ? `/event/${this.item.comment_album_event_slug}`
          : this.item.comment_event_slug
          ? `/event/${this.item.comment_event_slug}`
          : this.item.comment_video_event_slug
          ? `/event/${this.item.comment_video_event_slug}`
          : this.item.event_slug
          ? `/event/${this.item.event_slug}`
          : this.item.album_event_slug
          ? `/event/${this.item.album_event_slug}`
          : this.item.video_event_slug
          ? `/event/${this.item.video_event_slug}`
          : this.item.attending_event_slug
          ? `/event/${this.item.attending_event_slug}`
          : this.item.laptime_video_event_slug
          ? `/event/${this.item.laptime_video_event_slug}`
          : this.item.like_video_event_slug
          ? `/event/${this.item.like_video_event_slug}`
          : null
      },
      organiserUrl() {
        return this.item.comment_organiser_slug ? `/organiser/${this.item.comment_organiser_slug}` : null
      },
      profileUrl() {
        return `/${this.item.username}`
      },
      thumbnailUrl() {
        return this.item.photograph !== 'albums'
          ? this.item.photograph
          : this.item.comment_album_thumbnail !== 'albums'
          ? this.item.comment_album_thumbnail
          : this.item.comment_photograph !== 'albums'
          ? this.item.comment_photograph
          : this.item.album_thumbnail !== 'albums'
          ? this.item.album_thumbnail
          : this.item.thumbnail !== 'albums'
          ? this.item.thumbnail
          : this.item.like_photograph !== 'albums'
          ? this.item.like_photograph
          : null
      },
      userIcon() {
        return this.item.comment
          ? 'CommentIcon'
          : this.item.photograph_uid
          ? 'PhotographIcon'
          : this.item.album_name
          ? 'PhotographIcon'
          : this.item.comment_video_uid
          ? 'VideoIcon'
          : this.item.laptime_time
          ? 'LeaderboardIcon'
          : 'EventIcon'
      },
      hasVideo() {
        return (
          !!this.item.comment_video_youtube_id ||
          !!this.item.video_youtube_id ||
          !!this.item.laptime_video_youtube_id ||
          !!this.item.like_video_youtube_id
        )
      },
      youtubeId() {
        return {
          youtube_id: this.item.comment_video_youtube_id
            ? this.item.comment_video_youtube_id
            : this.item.video_youtube_id
            ? this.item.video_youtube_id
            : this.item.laptime_video_youtube_id
            ? this.item.laptime_video_youtube_id
            : this.item.like_video_youtube_id
            ? this.item.like_video_youtube_id
            : null
        }
      }
    },
    created() {
      this.loadContent()
    },
    methods: {
      async loadContent() {
        this.contentUid = this.item.album_uid
          ? this.item.album_uid
          : this.item.video_uid
          ? this.item.video_uid
          : this.item.laptime_video_uid
          ? this.item.laptime_video_uid
          : this.item.comment_event_uid
          ? this.item.comment_event_uid
          : this.item.comment_photograph_uid
          ? this.item.comment_photograph_uid
          : this.item.comment_album_uid
          ? this.item.comment_album_uid
          : this.item.comment_video_uid
          ? this.item.comment_video_uid
          : this.item.like_photograph_uid
          ? this.item.like_photograph_uid
          : this.item.like_video_uid
          ? this.item.like_video_uid
          : this.item.comment_uid
          ? this.item.comment_uid
          : null

        if (this.contentUid && !!parseInt(this.item.comment_count)) await this.getComments(this.contentUid)
      },
      async showComments() {
        this.isCommentsVisible = !this.isCommentsVisible
      },
      async getComments(contentUid, update = false) {
        if (this.comments && !update) return

        try {
          this.comments = await this.apiRequest(
            '/comment/comments',
            null,
            { u: contentUid },
            { Authorization: this.userJWT }
          )

          if (!!!this.comments) this.comments = []
        } catch (e) {
          if (e) console.log(`Error getting comments: ${e}`)
        }
      },
      async updateCommentList(commentUid) {
        if (commentUid) {
          this.comments = this.comments.filter((comment) => {
            return comment.uid !== commentUid
          })
        } else {
          this.getComments(this.contentUid, true)
        }
      },
      async goTo(url) {
        this.$router.push({
          path: url
        })
      },
      async showVideo(videoUid) {
        try {
          const video = await this.apiRequest(`/video/${videoUid}`, null, null, {
            Authorization: this.userJWT
          })

          this.$store.dispatch('setOverlay', {
            uid: video.uid,
            youtube_id: video.youtube_id,
            video_uid: video.uid,
            owner_uid: video.owner_uid,
            title: `${video.circuit_name} - ${video.layout_name}`,
            content: video.description,
            liked: video.liked,
            meta: {
              username: video.username,
              first_name: video.first_name,
              last_name: video.last_name,
              date: video.event_date,
              url: video.event_slug,
              lap_time: video.lap_time,
              car: {
                name: video.car_name,
                description: video.car_description
              }
            }
          })
        } catch (e) {
          if (e) console.log(`Error getting video: ${e}`)
        }
      },
      async showPhotograph(photographUid) {
        try {
          const photograph = await this.apiRequest(`/photograph/${photographUid}`, null, null, {
            Authorization: this.userJWT
          })

          this.$store.dispatch('setOverlay', {
            uid: photographUid,
            imagePath: `${process.env.VUE_APP_IMGIX}/${photograph.path}?w=1920&ar=1.5`,
            photograph_uid: photographUid,
            liked: photograph.liked,
            tags: photograph.tags
          })
        } catch (e) {
          if (e) console.log(`Error getting photograph: ${e}`)
        }
      },
      async likeContent() {
        try {
          const uid = this.item.comment_uid
            ? this.item.comment_uid
            : this.item.photograph_uid
            ? this.item.photograph_uid
            : this.item.like_photograph_uid
            ? this.item.like_photograph_uid
            : this.item.laptime_video_uid
            ? this.item.laptime_video_uid
            : this.item.video_uid
            ? this.item.video_uid
            : this.item.album_uid
            ? this.item.album_uid
            : null
          this.isLiking = true

          await this.apiRequest(
            `/like`,
            this.isLiked
              ? {
                  u: uid
                }
              : {
                  comment_uid: this.item.comment_uid ? this.item.comment_uid : null,
                  album_uid: this.item.album_uid ? this.item.album_uid : null,
                  photograph_uid: this.item.like_photograph_uid
                    ? this.item.like_photograph_uid
                    : this.item.photograph_uid
                    ? this.item.photograph_uid
                    : null,
                  video_uid: this.item.laptime_video_uid
                    ? this.item.laptime_video_uid
                    : this.item.video_uid
                    ? this.item.video_uid
                    : null,
                  n:
                    this.item.photograph_uid || this.item.like_photograph_uid
                      ? this.item.user_uid === (this.item.photograph_owner_uid || this.item.like_photograph_owner_uid)
                        ? this.item.user_uid
                        : null
                      : this.item.user_uid
                },
            null,
            {
              Authorization: this.userJWT
            },
            this.isLiked ? 'DELETE' : 'POST'
          )

          this.isLiked = !this.isLiked
          this.isLiking = false
        } catch (e) {
          if (e) console.log(`Error liking content: ${e}`)
        }
      },
      async submitForm(values) {
        this.isSaving = true

        const data = {
          contentUid: this.item.album_uid
            ? this.item.album_uid
            : this.item.video_uid
            ? this.item.video_uid
            : this.item.laptime_video_uid
            ? this.item.laptime_video_uid
            : this.item.comment_event_uid
            ? this.item.comment_event_uid
            : this.item.comment_photograph_uid
            ? this.item.comment_photograph_uid
            : this.item.comment_album_uid
            ? this.item.comment_album_uid
            : this.item.comment_video_uid
            ? this.item.comment_video_uid
            : this.item.like_photograph_uid
            ? this.item.like_photograph_uid
            : this.item.like_video_uid
            ? this.item.like_video_uid
            : this.item.comment_uid
            ? this.item.comment_uid
            : null,
          reply: this.item.comment_reply_uid
            ? this.item.comment_reply_uid
            : this.item.comment_uid
            ? this.item.comment_uid
            : null,
          comment: values.comment,
          n: this.item.like_photograph_owner_uid
            ? this.item.like_photograph_owner_uid
            : this.item.photograph_owner_uid
            ? this.item.photograph_owner_uid
            : this.item.user_uid
        }

        try {
          await this.apiRequest(`/comment`, data, null, { Authorization: this.userJWT }, 'POST')

          this.$store.dispatch('setDialog', {
            title: 'Success',
            content: '<p>Comment added!</p>',
            ctaTitle: 'Close',
            confirmed: true
          })
        } catch (e) {
          this.form.initial.comment = e.data.comment

          this.$store.dispatch('setDialog', {
            title: 'Error',
            content: e ? `<p>${e.data.message}</p>` : "<p>Sorry we couldn't post your comment</p>",
            ctaTitle: 'Close',
            error: true
          })
        }

        this.isSaving = false
        this.isReplying = false
      }
    }
  }
</script>
