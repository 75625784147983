<template>
  <section v-if="laptimes || leaderboards || layouts">
    <h2 class="text-xl font-bold">{{ circuitName }}</h2>
    <template v-if="laptimes">
      <MoleculeLapTimes v-if="laptimes" :laptimes="laptimes" :is-editable="isEditable" @update-laps="updateLapList" />
    </template>
    <template v-if="leaderboards">
      <MoleculeLeaderboard
        v-for="leaderboard in leaderboards"
        :key="leaderboard.uid"
        :circuit-name="circuitName"
        :leaderboard="leaderboard"
        @update-laps="updateLapList"
      />
    </template>
    <template v-if="layouts">
      <article v-for="(laps, layoutName, index) in layouts" :key="index">
        <h3 v-if="layoutName !== 'None'" :class="['text-lg my-4']">
          {{ layoutName }}
        </h3>

        <MoleculeLapTimes
          v-if="laps"
          :laptimes="laps"
          :circuit-name="circuitName"
          :layout-name="layoutName"
          :circuit-uid="laps[0].circuit_uid"
          :layout-uid="laps[0].layout_uid"
          :is-dashboard="true"
          :is-editable="isEditable"
          @update-laps="updateLapList"
        />
      </article>
    </template>
  </section>
</template>

<script>
  export default {
    props: {
      leaderboards: Array,
      laptimes: Array,
      layouts: Object,
      circuitName: String,
      isEditable: {
        type: Boolean,
        default: true
      }
    },
    emits: ['update-laps'],
    methods: {
      updateLapList(lapUid) {
        this.$emit('update-laps', lapUid)
      }
    }
  }
</script>
