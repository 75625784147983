<template>
  <div :class="[!onFeed ? 'mb-4' : null]">
    <ul class="space-y-2">
      <li v-for="comment in commentList" :key="comment.created_at">
        <AtomComment
          :parent-uid="parentUid"
          :overlay-uid="overlayUid"
          :comment="comment"
          :on-feed="onFeed"
          :highlight="highlightUid === comment.uid"
          @deleted="updateCommentList"
          @added="updateCommentList"
        />
      </li>
    </ul>
  </div>
</template>

<script>
  import AtomComment from '@/components/atoms/Comment.vue'

  export default {
    components: {
      AtomComment
    },
    props: {
      comments: Array,
      feed: Boolean,
      highlightUid: String,
      overlayUid: String,
      parentUid: String
    },
    emits: ['update-comments'],
    computed: {
      commentList() {
        const comments = this.comments.filter((comment) => {
          comment.replies = []
          return !!!comment.reply_to_uid
        })
        const replies = this.comments.filter((comment) => {
          return !!comment.reply_to_uid
        })

        if (replies) {
          replies.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())

          replies.forEach((reply) => {
            const comment = comments.find((comment) => comment.uid === reply.reply_to_uid)

            if (comment) comment.replies.push(reply)
          })
        }

        return comments
      },
      onFeed() {
        return !!this.feed
      }
    },
    methods: {
      async updateCommentList(commentUid) {
        if (commentUid) {
          this.$emit('update-comments', commentUid)
        } else {
          this.$emit('update-comments')
        }
      }
    }
  }
</script>
