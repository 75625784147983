<template>
  <div class="mx-auto w-full max-w-screen-2xl">
    <div class="pb-4 pt-6 px-4 lg:pt-10 lg:px-8">
      <h2 class="text-black text-2xl font-extrabold tracking-tight leading-9 lg:text-4xl">
        <template v-if="title">{{ title }}</template>
        <template v-else>––––</template>
      </h2>
      <p v-if="content" class="flex items-center mt-2 text-black text-lg leading-7">
        <BaseIcon v-if="icon" class="mr-1 w-7 h-7"><component :is="icon" /></BaseIcon>{{ content }}
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: String,
      content: String,
      icon: String
    }
  }
</script>
