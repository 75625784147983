<template>
  <div class="lg:grid lg:gap-8 lg:grid-cols-3">
    <div class="space-y-4">
      <h2 class="text-gray-900 text-3xl font-extrabold leading-9">
        {{ title }}
      </h2>
      <p class="text-gray-500 text-lg leading-7" v-html="content"></p>
    </div>
    <div class="mt-12 lg:col-span-2 lg:mt-0">
      <dl class="space-y-12">
        <div v-for="item in items" :key="item.title" class="space-y-2">
          <dt class="text-gray-900 text-lg font-medium leading-6">
            {{ item.title }}
          </dt>
          <dd class="text-gray-500 text-base leading-6">
            {{ item.content }}
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: String,
      content: String,
      items: Array
    }
  }
</script>
