<template>
  <ol>
    <AtomEvent
      v-for="(event, i) in events"
      :key="event.uid"
      :event="event"
      :full="full"
      :preview="preview"
      :simple="simple"
      :last="!simple ? i === events.length - 1 : null"
      :class="[preview ? (i > 1 ? 'hidden lg:block' : null) : null]"
    />
  </ol>
</template>

<script>
  export default {
    props: {
      events: Array,
      full: Boolean,
      preview: Boolean,
      simple: Boolean
    }
  }
</script>
