<template>
  <Form class="space-y-6" :validation-schema="schema" @submit="submitForm">
    <h3 v-if="title" class="text-center text-black text-3xl font-extrabold">{{ title }}</h3>
    <div v-for="field in fields" v-show="field.type !== 'hidden'" :key="field.id">
      <label for="field.id" class="block text-black text-sm font-semibold">
        {{ field.label }}
      </label>
      <div class="mt-1">
        <Field
          :id="field.id"
          :name="field.id"
          :as="field.elem"
          :type="field.type"
          :rules="field.isRequired"
          :value="field.value"
          :disabled="field.disabled"
          :hidden="field.hidden"
          class="
            form-input
            block
            px-4
            py-2
            w-full
            text-black
            border-black
            focus:border-orange-500
            rounded-sm
            focus:outline-none
            shadow-none
            transition
            duration-150
            ease-in-out
            focus:ring-0
          "
        />
        <ErrorMessage :name="field.id" />
      </div>
    </div>
    <div>
      <button
        :disabled="isLoading"
        type="submit"
        :class="[
          'w-full inline-flex items-center justify-center px-6 py-2 border border-transparent text-base leading-6 font-medium rounded-sm text-white bg-orange-500 focus:outline-none focus:border-orange-500 transition-colors ease-in-out duration-150',
          !isLoading ? 'lg:hover:bg-orange-300' : 'bg-orange-500 cursor-default'
        ]"
      >
        <span v-if="!isLoading">{{ cta }}</span>
        <AtomLoader v-else></AtomLoader>
      </button>
    </div>
    <div v-if="action === 'auth/signUserIn'" class="flex items-center justify-between">
      <div class="text-sm">
        <RouterLink
          class="text-blakc lg:hover:text-orange-500 text-black font-medium transition-colors duration-150 ease-in-out"
          to="/join"
        >
          Create an account
        </RouterLink>
      </div>

      <div class="text-sm">
        <RouterLink
          class="text-blakc lg:hover:text-orange-500 text-black font-medium transition-colors duration-150 ease-in-out"
          to="/password-reset"
        >
          Forgot your password?
        </RouterLink>
      </div>
    </div>
    <div v-if="action === 'auth/signUserUp'" class="mt-0">
      <div class="flex items-center justify-end">
        <div class="text-black text-sm">
          <p class="inline">Have an account?</p>
          <RouterLink
            class="
              text-blakc
              lg:hover:text-orange-700
              text-orange-500
              font-medium
              transition-colors
              duration-150
              ease-in-out
            "
            to="/sign-in"
          >
            Log in
          </RouterLink>
        </div>
      </div>
    </div>
    <div v-if="action === 'auth/requestPasswordResetLink'">
      <div class="flex items-center justify-end">
        <div class="text-black text-sm">
          <p class="inline">Not received an email?</p>
          <RouterLink class="text-orange-500 hover:text-orange-700 font-medium" to="/contact">
            Contact support.
          </RouterLink>
        </div>
      </div>
    </div>
  </Form>
</template>

<script>
  import { Field, Form, ErrorMessage, useForm } from 'vee-validate'
  import * as yup from 'yup'

  export default {
    components: {
      ErrorMessage,
      Field,
      Form
    },
    props: {
      action: String,
      cta: String,
      fields: Array,
      title: String
    },
    data() {
      const schema = yup.object().shape({
        email: yup.string().required().email().label('Email Address').nullable(),
        password: yup.string().required().min(6).label('Password')
      })

      return {
        isLoading: false,
        schema
      }
    },
    methods: {
      async submitForm(values, { resetForm }) {
        this.isLoading = true

        await this.$store.dispatch(`${this.action}`, values)

        setTimeout(() => {
          this.isLoading = false
        }, 100)
      }
    }
  }
</script>
