<template>
  <article class="flex flex-1 flex-col flex-wrap justify-start">
    <h3 v-if="leaderboard && leaderboard.layout !== 'None'" class="my-4 text-lg">
      {{ leaderboard.layout }}
      <!-- <span class="font-normal text-sm">
        ({{ leaderboard.length }} miles)
      </span> -->
    </h3>

    <MoleculeLapTimes
      v-if="leaderboard.laptimes"
      :circuit-name="circuitName"
      :layout-name="leaderboard.layout"
      :laptimes="leaderboard.laptimes"
      :circuit-uid="leaderboard.circuit_uid"
      :layout-uid="leaderboard.layout_uid"
      @update-laps="updateLapList"
    />
  </article>
</template>

<script>
  export default {
    props: {
      leaderboard: Object,
      circuitName: String
    },
    emits: ['update-laps'],
    methods: {
      updateLapList(lapUid) {
        this.$emit('update-laps', lapUid)
      }
    }
  }
</script>
