<template>
  <RouterLink
    :to="organiserUrl"
    class="group aspect-w-4 aspect-h-3 group relative block w-full bg-white border border-gray overflow-hidden"
  >
    <img
      v-if="thumbnail"
      :src="thumbnail"
      :class="[
        'absolute max-w-xxs mx-auto h-full w-full object-contain z-20 p-8 md:p-12 transform-gpu lg:group-hover:scale-105 transition-all ease-in-out duration-150',
        !isImageLoaded ? 'opacity-0' : 'opacity-100'
      ]"
      :alt="organiser.name"
      @load="imageLoaded"
    />
    <div
      :class="[
        'bg-white flex items-center justify-center transition ease-in-out duration-150',
        isImageLoaded ? 'opacity-0' : 'opacity-100'
      ]"
    >
      <AtomLogo />
    </div>
  </RouterLink>
</template>

<script>
  export default {
    props: {
      organiser: Object
    },
    data() {
      return {
        isImageLoaded: false
      }
    },
    computed: {
      organiserUrl() {
        return `/organiser/${this.organiser.slug}`
      },
      thumbnail() {
        return this.organiser ? `${process.env.VUE_APP_IMGIX}/organisers/${this.organiser.slug}.png` : null
      }
    },
    methods: {
      imageLoaded() {
        this.isImageLoaded = true
      }
    }
  }
</script>
