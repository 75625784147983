<template>
  <div class="bg-gray-50">
    <div class="mx-auto px-4 py-16 max-w-screen-xl sm:px-6 sm:py-24 lg:px-8">
      <h2 v-if="title" class="text-gray-900 text-center text-3xl font-extrabold leading-9">
        {{ title }}
      </h2>
      <div class="mt-12">
        <dl class="space-y-10 md:grid md:gap-x-8 md:gap-y-12 md:grid-cols-2 md:grid-rows-2 md:space-y-0 lg:grid-cols-3">
          <div v-for="item in items" :key="item.title" class="space-y-2">
            <dt class="text-gray-900 text-lg font-medium leading-6">
              {{ item.title }}
            </dt>
            <dd class="text-gray-500 text-base leading-6">
              {{ item.content }}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: String,
      items: Object
    }
  }
</script>
