<template>
  <div id="Filters">
    <form :class="classes">
      <div v-for="field in fields" :key="field.id" :class="field.classes">
        <template v-if="field.elem === 'select'">
          <label :for="field.id" class="text-gray-700 hidden text-sm font-medium capitalize">
            {{ field.label }}
          </label>
          <div class="mt-1">
            <select
              :id="field.id"
              v-model="filters[field.id]"
              class="
                form-input
                block
                pl-3
                pr-10
                py-3
                w-full
                border border-gray
                focus:border-orange-500
                rounded-sm
                focus:outline-none
                transition
                duration-150
                ease-in-out
                focus:ring-orange-500
              "
              :name="field.id"
              @change="updateFilteredEvents"
            >
              <template v-if="field.id === 'start_date'">
                <option selected disabled value="null">{{ field.label }}</option>
                <template v-for="group in field.children" :key="group.months">
                  <optgroup v-if="group.value && group.value.year" :label="group.value.year">
                    <option :value="group.value.year">{{ group.value.year }}</option>
                    <option
                      v-for="(option, index) in group.value.months"
                      :key="index"
                      :value="`${group.value.year}-${String(option).padStart(2, '0')}-01`"
                    >
                      {{ months[parseInt(option) - 1] }}
                    </option>
                  </optgroup>
                  <option v-else>{{ group.year }}</option>
                </template>
              </template>
              <template v-else-if="field.children && field.children.length">
                <option v-if="!field.defaultAll" :selected="!field.defaultAll" disabled value="null">
                  {{ field.label }}
                </option>
                <option :selected="field.defaultAll" :value="field.defaultAll ? null : 'All'">
                  All {{ field.label }}s
                </option>
                <option v-for="option in field.children" :key="option.text" :value="option.value">
                  {{ option.text }}
                </option>
              </template>
            </select>
          </div>
        </template>
        <div v-else class="flex items-center">
          <input
            :id="field.id"
            v-model="filters[field.id]"
            class="mr-2 text-orange-500 focus:outline-none shadow-none focus:shadow-none cursor-pointer"
            :type="field.elem"
            @change="updateFriendsOnly($event.target.checked)"
          />
          <label :for="field.id" class="cursor-pointer">{{ field.label }}</label>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    props: {
      classes: String,
      fields: Array
    },
    emits: ['update-content'],
    data() {
      return {
        form: null,
        friendsOnly: false,
        filters: {},
        months: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ]
      }
    },
    watch: {
      // Watch query params
      '$route.params': function () {
        // If no query params
        if (Object.keys(this.$route.query).length === 0) {
          // Set each filter to null
          this.fields.forEach((field) => {
            this.filters[field.id] = null
          })
        }
      }
    },
    created() {
      // Set default selected state from query param (or null)
      this.fields.forEach((field) => {
        this.filters[field.id] = this.$route.query[field.id] ? this.$route.query[field.id] : null
      })
    },
    mounted() {
      this.$emit('update-content', this.filters)
    },
    methods: {
      updateFriendsOnly(isChecked) {
        this.friendsOnly = isChecked
        this.updateFilteredEvents()
      },
      updateFilteredEvents() {
        const values = this.filters
        const obj = {}

        // Loop through all filters
        Object.keys(values).forEach((key) => {
          if (key === 'friends_attending') {
            if (this.friendsOnly) {
              obj[key] = this.friendsOnly
            } else {
              delete obj[key]
            }
          } else {
            // If a value is selected, add it to 'obj' - else delete from 'obj'
            if (values[key] !== 'All' && values[key] !== undefined && values[key] !== null && values[key] !== false) {
              obj[key] = values[key]
            } else {
              delete obj[key]
            }
          }
        })

        // Update query params with 'obj' constructed from 'this.filters'
        this.$router.push({
          query: obj
          // hash: '#Filters'
        })

        this.$emit('update-content', obj)
      }
    }
  }
</script>
