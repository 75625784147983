<template>
  <div class="flex flex-col flex-wrap text-left border border-gray">
    <RouterLink :to="albumUrl" class="group aspect-w-3 aspect-h-2 relative block w-full bg-gray overflow-hidden">
      <img
        v-if="thumbnail"
        :src="`${thumbnail}?w=768&ar=1.5`"
        :class="[
          'absolute h-full w-full object-cover z-10 transform-gpu lg:group-hover:scale-105 transition-all ease-in-out duration-150',
          !isImageLoaded ? 'opacity-0' : 'opacity-100'
        ]"
        :alt="album.name"
        @load="imageLoaded"
      />
      <div
        class="
          lg:group-hover:bg-orange-500 lg:group-hover:text-white
          flex
          items-center
          justify-center
          bg-gray
          transition
          duration-150
          ease-in-out
        "
      >
        <AtomLogo />
      </div>
    </RouterLink>
    <div class="flex flex-1 flex-col justify-between p-4 pt-2 w-full lg:p-6 lg:pt-4">
      <div class="flex flex-1 flex-col w-full">
        <p
          v-if="
            ((album.name || album.album_name) && onOrganiser) ||
            ((album.name || album.album_name) && !album.organiser_album) ||
            ((album.name || album.album_name) && onEvent)
          "
          class="text-2xl font-bold leading-9"
        >
          <template v-if="album.name">{{ album.name }}</template>
          <template v-else>{{ album.album_name }}</template>
        </p>
        <div v-else class="text-2xl font-bold leading-9">
          <p>
            {{ album.circuit_name }}
            <template v-if="album.layout_name && album.layout_name !== 'Full Circuit'">
              - {{ album.layout_name }}
            </template>
          </p>
        </div>
        <div :class="['grid gap-2 grid-cols-2 mt-4']">
          <RouterLink
            v-if="circuitUrl && onCircuit !== true"
            :to="circuitUrl"
            class="flex items-center hover:text-orange-500 transition-colors duration-150 ease-in-out"
            active-class="text-black"
            exact-active-class="text-black"
          >
            <BaseIcon class="flex-none mr-1">
              <CircuitIcon />
            </BaseIcon>
            <template v-if="album.circuit_name">{{ album.circuit_name }}</template>
            <template v-else-if="album.circuit">{{ album.circuit }}</template>
          </RouterLink>

          <RouterLink
            v-if="organiserUrl && onCircuit === true"
            :to="organiserUrl"
            class="flex items-center hover:text-orange-500 transition-colors duration-150 ease-in-out"
          >
            <BaseIcon class="flex-none mr-1"><OrganiserIcon /></BaseIcon>
            <template v-if="album.organiser">{{ album.organiser }}</template>
            <template v-else-if="album.organiser_name">{{ album.organiser_name }}</template>
          </RouterLink>

          <RouterLink
            v-if="eventUrl && onEvent === false"
            :to="eventUrl"
            class="flex items-start hover:text-orange-500 transition-colors duration-150 ease-in-out"
          >
            <BaseIcon class="flex-none mr-1">
              <EventIcon />
            </BaseIcon>
            <AtomFormattedDate :date="album.event_date" format="medium" />
            <template v-if="isEveningEvent"> (Evening)</template>
          </RouterLink>

          <template v-if="!album.organiser_album && albumOwner">
            <RouterLink
              v-if="profileUrl && onDashboard === false"
              :to="profileUrl"
              class="flex items-center hover:text-orange-500 transition-colors duration-150 ease-in-out"
            >
              <BaseIcon class="flex-none mr-1">
                <PersonIcon />
              </BaseIcon>
              {{ albumOwner }}
            </RouterLink>
          </template>
          <template v-else>
            <RouterLink
              v-if="organiserUrl && onDashboard === false && onCircuit === false"
              :to="organiserUrl"
              class="flex items-center hover:text-orange-500 transition-colors duration-150 ease-in-out"
              active-class="text-black"
              exact-active-class="text-black"
            >
              <BaseIcon class="flex-none mr-1">
                <OrganiserIcon />
              </BaseIcon>
              {{ album.organiser_name }}
            </RouterLink>
          </template>
          <RouterLink
            v-if="hasComments"
            class="flex items-center hover:text-orange-500 transition-colors duration-150 ease-in-out"
            :to="`${albumUrl}#comments`"
          >
            <BaseIcon class="mr-1 w-7 h-7">
              <CommentIcon />
            </BaseIcon>
            {{ album.comments }} comment<template v-if="album.comments > 1">s</template>
          </RouterLink>
        </div>
      </div>

      <div :class="['grid gap-4', isAlbumOwner || isAdmin ? 'grid-cols-2' : null]">
        <AtomCTA v-if="loggedInUser" type="RouterLink" :to="albumUrl" title="View album" ghost />
        <AtomCTA v-else type="RouterLink" to="/join" title="Join to view album" ghost />
        <button
          v-if="isAlbumOwner || isAdmin"
          class="
            lg:hover:border-black
            flex
            items-center
            justify-center
            mt-4
            p-2
            w-full
            text-black
            bg-white
            border border-gray
            rounded-sm
            focus:outline-none
            transition-all
            duration-300
            ease-in-out
          "
          @click.prevent="deleteAlbum()"
        >
          Delete album
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { Auth } from 'aws-amplify'
  import { mapGetters } from 'vuex'

  export default {
    props: {
      album: {
        type: Object
      },
      onEvent: {
        type: Boolean,
        default: false
      },
      onDashboard: {
        type: Boolean,
        default: false
      },
      onOrganiser: {
        type: Boolean,
        default: false
      },
      onCircuit: {
        type: Boolean,
        default: false
      }
    },
    emits: ['deleted'],
    data() {
      return {
        isImageLoaded: false
      }
    },
    computed: {
      ...mapGetters({
        userJWT: 'getJWT',
        loggedInUser: 'auth/getLoggedInUser'
      }),
      thumbnail() {
        return this.album.thumbnail_uid && this.album.thumbnail_uid.trim()
          ? `${process.env.VUE_APP_IMGIX}/${this.album.thumbnail}`
          : null
      },
      albumUrl() {
        return `/album/${this.album.album_uid ? this.album.album_uid : this.album.uid}`
      },
      circuitUrl() {
        return this.album.circuit_slug ? `/circuit/${this.album.circuit_slug}` : null
      },
      organiserUrl() {
        return this.album.organiser_slug ? `/organiser/${this.album.organiser_slug}` : null
      },
      eventUrl() {
        return this.album.event_slug ? `/event/${this.album.event_slug}` : null
      },
      isEveningEvent() {
        return this.album.event_slug.includes('evening')
      },
      profileUrl() {
        return this.album.username ? `/${this.album.username}` : null
      },
      isAlbumOwner() {
        return this.album.owner_uid === (this.loggedInUser && this.loggedInUser.id)
      },
      hasComments() {
        return this.album.comments && this.album.comments > 0
      },
      albumOwner() {
        const uuidRegEx = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi

        return !uuidRegEx.test(this.album.username)
          ? this.album.username
          : this.album.first_name && this.album.last_name
          ? `${this.album.first_name} ${this.album.last_name}`
          : null
      },
      isAdmin() {
        return this.loggedInUser && this.loggedInUser.admin
      }
    },
    methods: {
      async deleteAlbum() {
        try {
          await this.apiRequest(`/album/${this.album.uid}`, null, null, { Authorization: this.userJWT }, 'DELETE')

          this.$store.dispatch('setDialog', {
            title: 'Album Deleted!',
            content: '<p>The album has been deleted.</p>',
            ctaTitle: 'Close',
            confirmed: true
          })

          this.$emit('deleted', this.album.uid)
        } catch (e) {
          if (e) console.log(`Error deleting album: ${e.status} ${e.data}`)

          this.$store.dispatch('setDialog', {
            title: 'Error',
            content: "<p>Sorry we couldn't delete this album. Please try again.</p>",
            ctaTitle: 'Close',
            error: true
          })
        }
      },
      imageLoaded() {
        this.isImageLoaded = true
      }
    }
  }
</script>
