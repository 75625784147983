<template>
  <div class="flex flex-col overflow-hidden">
    <div class="relative flex-shrink-0">
      <RouterLink :to="circuitUrl" class="group aspect-w-3 aspect-h-2 relative block w-full bg-gray overflow-hidden">
        <p
          :class="[
            'text-2xl leading-9 font-bold absolute top-0 left-0 p-4 lg:p-6 z-20 w-auto h-auto transition-colors ease-in-out duration-150',
            !isImageLoaded ? 'lg:group-hover:text-white text-black' : 'text-white'
          ]"
        >
          {{ circuit.name }}
        </p>
        <img
          v-if="thumbnail"
          :src="`${thumbnail}?w=768&ar=1.5`"
          :class="[
            'absolute h-full w-full object-cover z-10 transform-gpu lg:group-hover:scale-105 transition-all ease-in-out duration-150',
            !isImageLoaded ? 'opacity-0' : 'opacity-100'
          ]"
          :alt="circuit.name"
          @load="imageLoaded"
        />
        <div
          class="
            lg:group-hover:bg-orange-500 lg:group-hover:text-white
            flex
            items-center
            justify-center
            bg-gray
            transition
            duration-150
            ease-in-out
          "
        >
          <AtomLogo />
        </div>
      </RouterLink>
    </div>
  </div>
</template>

<script>
  import { Auth } from 'aws-amplify'
  import { mapGetters } from 'vuex'

  export default {
    props: {
      circuit: Object
    },
    emits: ['deleted'],
    data() {
      return {
        isImageLoaded: null
      }
    },
    computed: {
      ...mapGetters({
        userJWT: 'getJWT',
        loggedInUser: 'auth/getLoggedInUser'
      }),
      circuitUrl() {
        return `/circuit/${this.circuit.slug}`
      },
      thumbnail() {
        return this.circuit.cover_photograph_uid ? `${process.env.VUE_APP_IMGIX}/${this.circuit.thumbnail}` : null
      }
    },
    methods: {
      async deleteCircuit() {
        try {
          await this.apiRequest(`/circuit/${this.circuit.uid}`, null, null, { Authorization: this.userJWT }, 'DELETE')

          this.$store.dispatch('setDialog', {
            title: 'Circuit Deleted!',
            content: '<p>The circuit has been deleted.</p>',
            ctaTitle: 'Close',
            confirmed: true
          })

          this.$emit('deleted', this.circuit.uid)
        } catch (e) {
          if (e) console.log(`Error deleting circuit: ${e.status} ${e.data}`)

          this.$store.dispatch('setDialog', {
            title: 'Error',
            content: "<p>Sorry we couldn't delete this circuit. Please try again.</p>",
            ctaTitle: 'Close',
            error: true
          })
        }
      },
      imageLoaded() {
        this.isImageLoaded = true
      }
    }
  }
</script>
