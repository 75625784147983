<template>
  <div class="relative">
    <main class="flex flex-col-reverse flex-wrap">
      <div class="mx-auto pb-20 pt-16 w-full max-w-screen-2xl text-center lg:py-48 lg:text-left">
        <div class="px-4 sm:px-8 lg:w-1/2 xl:pr-16">
          <h1
            class="text-gray-900 text-4xl font-extrabold tracking-tight sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl"
          >
            <span class="block xl:inline">{{ title }} </span>
          </h1>
          <p class="text-gray-500 mt-3 mx-auto max-w-md text-lg sm:text-xl md:mt-5 md:max-w-3xl">
            {{ content }}
          </p>
          <div class="mt-10 sm:flex sm:justify-center lg:justify-start">
            <div
              v-for="(link, index) in links"
              :key="link.url"
              :class="['rounded-sm', index !== 0 ? 'mt-3 sm:mt-0 sm:ml-3' : '']"
            >
              <RouterLink
                :to="link.url"
                :class="[
                  'w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-sm md:py-4 md:text-lg md:px-10 transition-all duration-150 ease-in-out',
                  index === 0 ? 'text-white bg-blue-400 hover:bg-blue-700' : 'text-blue-600 bg-white hover:bg-blue-100'
                ]"
              >
                {{ link.title }}</RouterLink
              >
            </div>
          </div>
        </div>
      </div>
      <div class="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
        <img
          class="absolute inset-0 w-full h-full object-cover"
          src="https://images.unsplash.com/photo-1531328418211-f43ca4910bbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1280&q=80"
          alt=""
        />
      </div>
    </main>
  </div>
</template>

<script>
  export default {
    props: {
      title: String,
      content: String,
      links: Array
    }
  }
</script>
