<template>
  <div>
    <div class="mb-6 text-lg">
      <h1
        v-if="title"
        class="text-gray-900 text-center text-3xl font-extrabold tracking-tight leading-8 sm:text-4xl sm:leading-10"
      >
        {{ currentRouteName }}
      </h1>
      <slot name="intro"></slot>
    </div>
    <div class="prose text-gray-500">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  import { computed } from 'vue'
  import { useRoute } from 'vue-router'

  export default {
    props: {
      title: String
    },
    setup() {
      const route = useRoute()
      const currentRouteName = computed(() => route.name)

      return {
        currentRouteName
      }
    }
  }
</script>
