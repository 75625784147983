<template>
  <table class="min-w-full text-black text-base lg:h-10">
    <tbody>
      <AtomSingleLapTime
        v-for="(item, index) in laptimes"
        :key="item.id"
        :index="index"
        :circuit-name="circuitName"
        :circuit-uid="circuitUid"
        :layout-name="layoutName"
        :layout-uid="layoutUid"
        :event-uid="eventUid"
        :is-dashboard="isDashboard"
        :is-editable="isEditable"
        :item="item"
        @deleted="updateLapList"
      />
    </tbody>
  </table>
</template>

<script>
  export default {
    props: {
      laptimes: Array,
      circuitName: String,
      circuitUid: String,
      layoutName: String,
      layoutUid: String,
      eventUid: String,
      isDashboard: Boolean,
      isEditable: {
        type: Boolean,
        default: true
      }
    },
    emits: ['update-laps'],
    methods: {
      updateLapList(lapUid) {
        this.$emit('update-laps', lapUid)
      }
    }
  }
</script>
